import { titleCase } from "change-case";
import React, { useEffect, useRef } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { Avatar } from "../../Avatar";
import { useModalHook } from "../../Modals";
import { GothamMedium, GothamRegular } from "../../Text";
import { fileSizeString, isID, stringCutter } from "../../tools";
import { Files } from "./components";
import {
  fileTypesTranslatorArrayToString,
  useSetsValueFileInput,
} from "./logics";
import { Banner } from "../../Banner";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "react-i18next";

const singleFile = (files, multiple) => {
  const defaultObj = { file: {}, hasSubmitted: false };
  const notSubmitted = Array.isArray(files);

  if (multiple || notSubmitted) return defaultObj;

  return { file: files, hasSubmitted: true };
};

export const FileInputJack = ({
  useFormObj,
  name,
  label: labelProps,
  labelStyle,
  multiple = false,
  helperText = "",
  maxFiles = 5,
  maxSizeMB = 10,
  fileTypes = ["image/png", "application/pdf"],
  firstCopy = isID
    ? "Pilih file atau letakkan di sini"
    : "Browse or drag files here to upload",
  showLabel = true,
  containerMaxHeight,
  customError,
  enablePreview,
  middleComponent = null,
  bottomComponent = null,
  bannerMessage = "",
  isCreateVa = false,
  bannerTextStyle,
}) => {
  const label =
    typeof labelProps == "string" ? labelProps : labelProps || titleCase(name);
  const { register, setValue, getValues, watch } = useFormObj;
  const { t: tReim } = useTranslation("reimbursement/reimbursement");
  const { t } = useTranslation("va/create");
  const { t: tLocalTransfer } = useTranslation("local-transfer/local-transfer");

  const fileTypeString = fileTypesTranslatorArrayToString(fileTypes);
  const {
    setsValue,
    errors,
    reset,
    ref: inputRef,
  } = useSetsValueFileInput({
    fileTypes,
    maxFiles,
    maxSizeMB,
    multiple,
    name,
    useFormObj,
  });

  // const { isTooBig, isTypeNotMatch, isTooMuchFiles } = errors;

  const files = getValues()[name] || [];

  useEffect(() => {
    register({ name });
  }, []);

  const { isOpen: isDragging, close: notDrag, open: drag } = useModalHook();

  const stopsPropagation = (e) => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();
  };

  const ref = useRef();

  const height = ref?.current?.clientHeight + 8 || 0;

  const { file, hasSubmitted } = singleFile(files, multiple);

  const { locale } = useRouter();
  const isId = locale === "id";

  const content = () => {
    if (hasSubmitted) {
      const { name, size } = file;
      return (
        <>
          <JackIcons
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            onClick={(e) => {
              stopsPropagation(e);
              reset();
            }}
            name="close"
            fill={jackColors.grey6C}
          />

          <Avatar
            type="square"
            mainIcon={
              <JackIcons name="checkmark-square-2" fill={jackColors.greenB9} />
            }
          />
          <GothamMedium className="my-1">{stringCutter(name, 30)}</GothamMedium>
          {!!size && (
            <GothamRegular className="font12">
              {fileSizeString(size)}
            </GothamRegular>
          )}
        </>
      );
    }

    if (isCreateVa) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            alignItems: "center",
          }}
        >
          <Avatar
            type="square"
            mainIcon={
              <JackIcons name="file-outline" fill={jackColors.greenB9} />
            }
          />
          <GothamMedium
            className="font14"
            style={{ color: jackColors.black34 }}
          >
            {firstCopy}
          </GothamMedium>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.grey6C }}
          >
            <Trans i18nKey={t("dynamic.dynamic_text_1", { maxSizeMB })} />
          </GothamRegular>
          <GothamRegular
            className="font12"
            style={{
              color: jackColors.grey6C,
            }}
          >
            {t("Supported format:")}
            {fileTypeString}
          </GothamRegular>
        </div>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          alignItems: "center",
        }}
      >
        <Avatar
          type="square"
          mainIcon={<JackIcons name="file-outline" fill={jackColors.greenB9} />}
        />
        <GothamMedium className="font14" style={{ color: jackColors.black34 }}>
          {firstCopy}
        </GothamMedium>
        <GothamRegular className="font12" style={{ color: jackColors.grey6C }}>
          {tReim("Max. size for each file")}: {maxSizeMB} MB
        </GothamRegular>
        <GothamRegular
          className="font12"
          style={{
            color: jackColors.grey6C,
          }}
        >
          {tReim("Supported format")}: {fileTypeString}
        </GothamRegular>
      </div>
    );
  };

  return (
    <div
      className="d-flex"
      style={{
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div ref={ref}>
        {showLabel && (
          <GothamMedium className="mb-3" style={labelStyle}>
            {label}
          </GothamMedium>
        )}{" "}
        {!!bannerMessage && (
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <Banner msg={bannerMessage} textIconStyle={bannerTextStyle} />
          </div>
        )}
        <label
          onDragEnter={(e) => {
            stopsPropagation(e);
            drag();
          }}
          onDragLeave={(e) => {
            stopsPropagation(e);
            notDrag();
          }}
          onDragOver={(e) => {
            stopsPropagation(e);
          }}
          onDrop={(e) => {
            stopsPropagation(e);
            if (!e) return;
            e.persist();
            setsValue(e);
          }}
          style={{
            flexDirection: "column",
            minHeight: 144,
            border: "2px dashed #6C6C71",
            borderRadius: 8,
            backgroundColor: isDragging ? jackColors.greyF1 : "",
            position: "relative",
            marginBottom: 0,
          }}
          className="d-flex align-items-center justify-content-center hover"
        >
          {content()}
          <input
            type="file"
            style={{ display: "none" }}
            multiple={multiple}
            ref={inputRef}
            onChange={(e) => {
              if (!e) return;
              setsValue(e);
            }}
          />
        </label>
      </div>
      <GothamRegular
        className="font10"
        style={{ color: jackColors.grey90, position: "absolute", top: height }}
      >
        {helperText}
      </GothamRegular>
      {middleComponent}
      <Files
        files={files}
        multiple={multiple}
        inputRef={inputRef}
        name={name}
        setValue={setValue}
        containerMaxHeight={containerMaxHeight}
        customError={customError}
        enablePreview={enablePreview}
      />
      {bottomComponent}
    </div>
  );
};
